import React, { useState, useEffect, useRef } from "react";
import AOS from 'aos'
import 'aos/dist/aos.css'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import what from '../images/what_we_do.png'


import join from '../images/journey.png'
import understand from '../images/process/understanding.png'
import strategy from '../images/process/strategy.png'
import build from '../images/process/BUILDING.png'
import Brand from '../images/process/the_art.png'
import Design from '../images/process/dream_design.png'
import Best from '../images/process/best.png'
import Plan from '../images/process/planning.png'
import Bring from '../images/process/bringing.png'
import World from '../images/process/world_watches.png'
import Digital from '../images/process/digital.png'
import Influnce from '../images/process/power_of_influence.png'
import Globel from '../images/process/global.png'


import imgOne from '../images/ad.jpg';
import imgTwo from '../images/process/convo.jpg';
import imgThree from '../images/process/srategy.jpg';
import imgFour from '../images/process/foudation.jpg'
import imgFive from '../images/process/brand.jpg';
import imgSix from '../images/process/sourcing.jpg';
import imgSeven from '../images/process/designing.jpg';
import imgEight from '../images/process/planning.jpg';
import imgNine from '../images/process/bringing.jpg';
import imgTen from '../images/process/world_ad.jpg';
import imgElven from '../images/process/Digital_media.jpg';
import imgTwele from '../images/process/influencer.jpg';
import imgThritin from '../images/process/global_expansion.jpg';
import imgfourtin from '../images/process/last.jpg';



// icons
import { FaExternalLinkSquareAlt } from "react-icons/fa";
import Progressbar from "./Progressbar";



gsap.registerPlugin(ScrollTrigger);

const Allprocess = () => {

    const firstProcess = useRef(null)
    const secondProcess = useRef(null)
    const thirdProcess = useRef(null)
    const fourthProcess = useRef(null)
    const fifthProcess = useRef(null)
    const sixthProcess = useRef(null)
    const seventhProcess = useRef(null)
    const eighthProcess = useRef(null)
    const ninethProcess = useRef(null)
    const tenthProcess = useRef(null)
    const eleventhProcess = useRef(null)
    const twelethProcess = useRef(null)
    const thrtinthProcess = useRef(null)




    useEffect(() => {
        AOS.init({
            duration: 1000, // You can set a global duration if needed
        });
    }, []);



    useEffect(() => {

        const animation = gsap.matchMedia()


        animation.add("(min-width:800px)", () => {

            //one
            const first = gsap.timeline({
                scrollTrigger: {
                    trigger: "#proone",
                    start: "top 10%",
                    end: "bottom 80%",
                    scrub: 1,
                    toggleActions: "play none none reverse",
                    // markers: true
                },
            })

            first.to(firstProcess.current, {
                x: 0,
                opacity: 0,

            })

            //two
            const second = gsap.timeline({
                scrollTrigger: {
                    trigger: "#protwo",
                    start: "top 10%",
                    end: "bottom 80%",
                    scrub: 1,
                    toggleActions: "play none none reverse",
                    // markers: true
                },
            })

            second.to(secondProcess.current, {
                x: 0,
                opacity: 0,

            })


            //three
            const three = gsap.timeline({
                scrollTrigger: {
                    trigger: "#prothree",
                    start: "top 10%",
                    end: "bottom 80%",
                    scrub: 1,
                    toggleActions: "play none none reverse",
                    // markers: true
                },
            })

            three.to(thirdProcess.current, {
                x: 0,
                opacity: 0,

            })

            //four
            const four = gsap.timeline({
                scrollTrigger: {
                    trigger: "#profour",
                    start: "top 10%",
                    end: "bottom 80%",
                    scrub: 1,
                    toggleActions: "play none none reverse",
                    // markers: true
                },
            })

            four.to(fourthProcess.current, {
                x: 0,
                opacity: 0,
            })


            //five
            const five = gsap.timeline({
                scrollTrigger: {
                    trigger: "#profive",
                    start: "top 10%",
                    end: "bottom 80%",
                    scrub: 1,
                    toggleActions: "play none none reverse",
                    // markers: true
                },
            })

            five.to(fifthProcess.current, {
                x: 0,
                opacity: 0,
            })

            //six
            const six = gsap.timeline({
                scrollTrigger: {
                    trigger: "#prosix",
                    start: "top 10%",
                    end: "bottom 80%",
                    scrub: 1,
                    toggleActions: "play none none reverse",
                    // markers: true
                },
            })

            six.to(sixthProcess.current, {
                x: 0,
                opacity: 0,
            })


            //six
            const seven = gsap.timeline({
                scrollTrigger: {
                    trigger: "#proseven",
                    start: "top 10%",
                    end: "bottom 80%",
                    scrub: 1,
                    toggleActions: "play none none reverse",
                    // markers: true
                },
            })

            seven.to(seventhProcess.current, {
                x: 0,
                opacity: 0,
            })


            //eight
            const eight = gsap.timeline({
                scrollTrigger: {
                    trigger: "#proeight",
                    start: "top 10%",
                    end: "bottom 80%",
                    scrub: 1,
                    toggleActions: "play none none reverse",
                    // markers: true
                },
            })

            eight.to(eighthProcess.current, {
                x: 0,
                opacity: 0,
            })


            //nine
            const nine = gsap.timeline({
                scrollTrigger: {
                    trigger: "#pronine",
                    start: "top 10%",
                    end: "bottom 80%",
                    scrub: 1,
                    toggleActions: "play none none reverse",
                    // markers: true
                },
            })

            nine.to(ninethProcess.current, {
                x: 0,
                opacity: 0,
            })


            //ten
            const ten = gsap.timeline({
                scrollTrigger: {
                    trigger: "#proten",
                    start: "top 10%",
                    end: "bottom 80%",
                    scrub: 1,
                    toggleActions: "play none none reverse",
                    // markers: true
                },
            })

            ten.to(tenthProcess.current, {
                x: 0,
                opacity: 0,
            })


            //11
            const elven = gsap.timeline({
                scrollTrigger: {
                    trigger: "#proelven",
                    start: "top 10%",
                    end: "bottom 80%",
                    scrub: 1,
                    toggleActions: "play none none reverse",
                    // markers: true
                },
            })

            elven.to(eleventhProcess.current, {
                x: 0,
                opacity: 0,
            })


            //11
            const twele = gsap.timeline({
                scrollTrigger: {
                    trigger: "#protwele",
                    start: "top 10%",
                    end: "bottom 80%",
                    scrub: 1,
                    toggleActions: "play none none reverse",
                    // markers: true
                },
            })

            twele.to(twelethProcess.current, {
                x: 0,
                opacity: 0,
            })
            //11
            const thritin = gsap.timeline({
                scrollTrigger: {
                    trigger: "#prothritin",
                    start: "top 10%",
                    end: "bottom 80%",
                    scrub: 1,
                    toggleActions: "play none none reverse",
                    // markers: true
                },
            })

            thritin.to(thrtinthProcess.current, {
                x: 0,
                opacity: 0,
            })

        })

        //mobile view

        animation.add("(max-width:799px)", () => {

            //one
            // const first = gsap.timeline({
            //     scrollTrigger: {
            //         trigger: "#proone",
            //         start: "60% 10%",
            //         end: "bottom 80%",
            //         scrub: 1,
            //         toggleActions: "play none none reverse",
            //         // markers: true
            //     },
            // })

            // first.to(firstProcess.current, {
            //     x: 0,
            //     rotation: 200,
            //     duration: 80,
            //     opacity: 0,
            // })


            //two
            // const second = gsap.timeline({
            //     scrollTrigger: {
            //         trigger: "#protwo",
            //         start: "60% 10%",
            //         end: "bottom 80%",
            //         scrub: 1,
            //         toggleActions: "play none none reverse",
            //         // markers: true
            //     },
            // })

            // second.to(secondProcess.current, {
            //     x: 0,
            //     rotation: 200,
            //     duration: 80,
            //     opacity: 0,
            // })


            //three
            // const three = gsap.timeline({
            //     scrollTrigger: {
            //         trigger: "#prothree",
            //         start: "60% 10%",
            //         end: "bottom 80%",
            //         scrub: 1,
            //         toggleActions: "play none none reverse",
            //         // markers: true
            //     },
            // })

            // three.to(thirdProcess.current, {
            //     x: 0,
            //     rotation: 200,
            //     duration: 80,
            //     opacity: 0,

            // })

            //four
            // const four = gsap.timeline({
            //     scrollTrigger: {
            //         trigger: "#profour",
            //         start: "60% 10%",
            //         end: "bottom 80%",
            //         scrub: 1,
            //         toggleActions: "play none none reverse",
            //         // markers: true
            //     },
            // })

            // four.to(fourthProcess.current, {
            //     x: 0,
            //     rotation: 200,
            //     duration: 80,
            //     opacity: 0,
            // })


            //five
            // const five = gsap.timeline({
            //     scrollTrigger: {
            //         trigger: "#profive",
            //         start: "60% 10%",
            //         end: "bottom 80%",
            //         scrub: 1,
            //         toggleActions: "play none none reverse",
            //         // markers: true
            //     },
            // })

            // five.to(fifthProcess.current, {
            //     x: 0,
            //     rotation: 200,
            //     duration: 80,
            //     opacity: 0,
            // })
            
        })

        return () => animation.revert();


    }, []);


    return (
        <>
            <section className="bg-black font-sans">

                <div className="flex flex-col md:flex-row items-start">


                    {/*-------- process Title ----------*/}

                    <div className="sticky top-28 z-0 md:z-10 h-[85vh] md:h-screen w-full md:w-[50%]  px-10 py-8  bg-white  drop-shadow-sm box ">
                        <div className="relative w-full  h-full text-white overflow-hidden flex font-sans">


                            {/* Overlay */}
                            {/* <div className="absolute inset-0 bg-black opacity-50 z-10 w-full"></div> */}


                            <div className="flex items-stretch gap-x-6 md:gap-x-16 h-full    ">

                                {/* right Border */}
                                <div className="flex relative flex-col items-center justify-center" data-aos="fade-down" data-aos-delay='500' data-aos-duration='4000'>
                                    <div className="border-l-2 md:border-l-4 border-black h-[30%] z-10" data-aos="fade-down" data-aos-duration='1000'></div>
                                    <div className=''>
                                        <Progressbar />
                                    </div>
                                    <div className="border-l border-black h-full absolute top-0 z-0"></div>
                                </div>

                                {/* WHY Title */}

                                <div className='flex flex-col gap-y-1 items-start justify-start font-triumvirate'>
                                    <p className="text-gray-900 uppercase  tracking-wide font-sans text-[12px] md:text-[14px] mt-6">Our Process</p>
                                    {/* <h2 className=' text-[28px] md:text-[52px] text-black font-light  font-sans' data-aos="flip-up" data-aos-duration='1000'>What We do From Vision to Victory</h2> */}

                                    <div className="relative mt-8 md:mt-10" data-aos='fade-right' data-aos-duration='1000'>
                                        <img src={what} className="w-[75%] object-cover" alt="none" />
                                        <div className="absolute  -bottom-3 md:-bottom-6 right-24 md:right-44">
                                            <h2 className="text-black text-[2.5rem] md:text-[4.5rem] animate-bounce">?</h2>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="w-full md:w-[50%] ">

                        {/*-------- process one ----------*/}
                        <div className="flex justify-end items-end opacity-1" id="proone" ref={firstProcess} >
                            <div className="sticky top-28 z-10 h-[85vh] md:h-[65vh]  w-full md:w-full   bg-white  drop-shadow-sm box flex justify-start items-start ">
                                <div className="relative w-full  h-full text-white overflow-hidden flex font-sans">

                                    <img
                                        src={imgOne}
                                        alt="Background"
                                        className='absolute inset-0 w-full h-full object-cover transition-opacity duration-700 ease-in-out transform  '
                                    />

                                    {/* Overlay */}
                                    <div className="absolute inset-0 bg-black opacity-50 z-10 w-full"></div>


                                    <div className="content-section flex flex-col space-y-8 z-20 w-full md:w-[85%] md:mt-16 mx-auto ">
                                        <div className='w-full text-left transition-opacity  px-4 py-6 md:px-0 md:py-0 duration-700 ease-in-out  ' data-aos='fade-up' >
                                            <div className="place-box text-[12px] font-light uppercase font-signika">
                                                Step 1
                                            </div>
                                            <div className="title-box-1  text-[24px] md:text-[30px] font-bold text-yellow-400 font-oswald" data-aos='fade-up'>
                                                From Spark to Spotlight:
                                            </div>
                                            {/* The Journey of a Fresh Brand */}
                                            <div className="mt-6">
                                                <img src={join} className="w-48 object-cover " alt="none" />
                                            </div>

                                            <div className="desc mt-8" data-aos='fade-up'>
                                                <p className="text-[12px] md:text-[14px] md:pr-20">
                                                    A brand waiting to be born—an idea brimming with potential but needing a guiding hand to bring it to life. That’s where we come in
                                                </p>
                                            </div>


                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>


                        {/*-------- process two ----------*/}
                        <div className="flex justify-end items-end opacity-1" id="protwo" ref={secondProcess} >

                            <div className="sticky top-28 z-10 h-[80vh] md:h-[65vh] w-full md:w-full    shadow-lg drop-shadow-sm   " >
                                <div className="relative   h-full w-full  text-white overflow-hidden  font-sans ">


                                    <img
                                        src={imgTwo}
                                        alt="Background"
                                        className='absolute  inset-0 w-full h-full object-cover transition-opacity duration-700 ease-in-out transform  '
                                    />

                                    {/* Overlay */}
                                    <div className="absolute inset-0 bg-black opacity-50 z-0 w-full"></div>


                                    <div className="content-section flex flex-col-reverse  gap-y-6 md:flex-row items-start justify-between space-y-8 z-20 w-full md:w-[85%] md:mt-16 mx-auto">


                                        <div className='w-full md:w-full text-right transition-opacity flex flex-col justify-end items-end duration-700 ease-in-out  px-4 py-6 md:px-0 md:py-0 ' data-aos='fade-up'>
                                            <div className="place-box text-[12px] font-light uppercase font-signika" >
                                                Step 2
                                            </div>
                                            <div className="title-box-1 text-[24px] md:text-[30px] font-bold text-yellow-400 font-oswald" data-aos='fade-up'>
                                                It Begins with a Conversation
                                            </div>
                                            <div className="mt-4">
                                                <img src={understand} className="w-48 object-cover " alt="none" />
                                            </div>
                                            <div className="desc mt-8" data-aos='fade-up'>
                                                <p className="text-[12px] md:text-[14px] md:pl-20">
                                                    We start by listening. Deeply. Understanding your vision, your dreams, and your goals. Your story is our first chapter, and we can’t wait to write it with you.
                                                </p>
                                            </div>


                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                        {/*-------- process three ----------*/}
                        <div className="flex justify-end items-end  opacity-1" id="prothree" ref={thirdProcess} >
                            <div className="sticky top-28 z-10 h-[85vh] md:h-[65vh]  w-full md:w-full  bg-white  drop-shadow-sm box flex justify-start items-start ">
                                <div className="relative w-full h-full  text-white overflow-hidden flex font-sans">

                                    <img
                                        src={imgThree}
                                        alt="Background"
                                        className='absolute inset-0 w-full h-full object-cover transition-opacity duration-700 ease-in-out transform  '
                                    />

                                    {/* Overlay */}
                                    <div className="absolute inset-0 bg-black opacity-50 z-10 w-full"></div>


                                    <div className="content-section flex flex-col md:flex-row gap-y-6 items-start  space-y-8 z-20 w-full md:w-[85%] md:mt-16 mx-auto">

                                        <div className='w-full text-left transition-opacity  duration-700 ease-in-out px-4 py-6 md:px-0 md:py-0 ' data-aos='fade-up'>
                                            <div className="place-box text-[12px] font-light uppercase font-signika" >
                                                Step 3
                                            </div>
                                            <div className="title-box-1 text-[24px] md:text-[32px] font-bold text-yellow-400 font-oswald" data-aos='fade-up'>
                                                {/* Strategy That Sets the Stage */}
                                            </div>


                                            <div className="mt-4 flex">
                                                <img src={strategy} className="w-64 object-cover " alt="none" />
                                            </div>

                                            <div className="desc mt-4" data-aos='fade-up'>
                                                <p className="text-[12px] md:text-[14px] md:pr-20">
                                                    With a clear understanding of your brand, we craft a strategy that feels like magic. A roadmap that positions your brand to stand out, resonate, and engage. We set the stage for greatness.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*-------- process Four ----------*/}
                        <div className="flex justify-end items-end  opacity-1" id="profour" ref={fourthProcess} >

                            <div className="sticky top-28 z-10 h-[80vh] md:h-[65vh]  w-full  md:w-full   bg-white  shadow-lg drop-shadow-sm ">
                                <div className="relative w-full h-full text-white overflow-hidden flex font-sans">

                                    <img
                                        src={imgFour}
                                        alt="Background"
                                        className='absolute inset-0 w-full h-full object-cover transition-opacity duration-700 ease-in-out transform  '
                                    />

                                    {/* Overlay */}
                                    <div className="absolute inset-0 bg-black opacity-50 z-10 w-full"></div>


                                    <div className="content-section flex  gap-y-6 md:flex-row justify-between items-start space-y-8 z-20 w-full md:w-[85%] md:mt-16 mx-auto ">


                                        <div className='w-full text-right flex flex-col justify-end  items-end transition-opacity  duration-700 ease-in-out  px-4 py-6 md:px-0 md:py-0' data-aos='fade-up'>
                                            <div className="place-box text-[12px] font-light uppercase font-signika" >
                                                Step 4
                                            </div>
                                            <div className="title-box-1 text-[24px] md:text-[32px] font-bold text-yellow-400 font-oswald" data-aos='fade-up'>
                                                {/* Building the Foundation */}
                                            </div>

                                            <div className="mt-4">
                                                <img src={build} className="w-64 object-cover " alt="none" />
                                            </div>

                                            <div className="desc mt-4" data-aos='fade-up'>
                                                <p className="text-[12px] md:text-[14px] md:pl-20">
                                                    Now that we have your brand’s heartbeat, we make it official. The legalities are handled, and your company stands tall, ready to conquer the world.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        {/*-------- process Five ----------*/}
                        <div className="flex justify-end items-end  opacity-1" id="profive" ref={fifthProcess} >
                            <div className="sticky top-28 z-10 h-[85vh] md:h-[65vh] w-full md:w-full  bg-white  drop-shadow-sm box flex justify-start items-start ">
                                <div className="relative w-full h-full  text-white overflow-hidden flex font-sans">

                                    <img
                                        src={imgFive}
                                        alt="Background"
                                        className='absolute inset-0 w-full h-full object-cover transition-opacity duration-700 ease-in-out transform  '
                                    />

                                    {/* Overlay */}
                                    <div className="absolute inset-0 bg-black opacity-50 z-10 w-full"></div>


                                    <div className="content-section flex flex-col gap-y-6 md:flex-row  items-start space-y-8 z-20 w-full md:w-[85%] md:mt-16 mx-auto">

                                        <div className='w-full text-left transition-opacity  duration-700 ease-in-out px-4 py-6 md:px-0 md:py-0' data-aos='fade-up'>
                                            <div className="place-box text-[12px] font-light uppercase font-signika">
                                                Step 5
                                            </div>
                                            <div className="title-box-1 text-[24px] md:text-[32px] font-bold text-yellow-400 font-oswald" data-aos='fade-up'>
                                                {/* The Art of Branding */}
                                            </div>

                                            <div className="mt-4">
                                                <img src={Brand} className="w-64 object-cover " alt="none" />
                                            </div>
                                            <div className="desc mt-4" data-aos='fade-up'>
                                                <p className="text-[12px] md:text-[14px] md:pr-20">
                                                    We dive into the creative depths to design your brand’s identity—a look, a feel, and a voice that captures who you are and speaks to those you seek to connect with. Every detail is meticulously crafted to tell your story at first sight.
                                                </p>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*-------- process Six ----------*/}
                        <div className="flex justify-end items-end opacity-1" id="prosix" ref={sixthProcess}>

                            <div className="sticky top-28 z-10 h-[80vh] md:h-[65vh] w-full  md:w-full   bg-white  shadow-lg drop-shadow-sm ">
                                <div className="relative w-full h-full  text-white overflow-hidden flex font-sans">

                                    <img
                                        src={imgSix}
                                        alt="Background"
                                        className='absolute inset-0 w-full h-full object-cover transition-opacity duration-700 ease-in-out transform  '
                                    />

                                    {/* Overlay */}
                                    <div className="absolute inset-0 bg-black opacity-50 z-10 w-full"></div>


                                    <div className="content-section flex gap-y-6 justify-between md:flex-row  items-start space-y-8 z-20 w-full md:w-[90%] md:mt-16 mx-auto">


                                        <div className='w-full  text-right flex flex-col justify-end items-end transition-opacity  duration-700 ease-in-out px-4 py-6 md:px-0 md:py-0 ' data-aos='fade-up'>
                                            <div className="place-box text-[12px] font-light uppercase font-signika">
                                                Step 6
                                            </div>
                                            <div className="title-box-1 text-[24px] md:text-[30px] font-bold text-yellow-400 font-oswald" data-aos='fade-up'>
                                                Sourcing the
                                            </div>
                                            {/* Best */}
                                            <div className="mt-4">
                                                <img src={Best} className="w-40 object-cover " alt="none" />
                                            </div>
                                            <div className="desc mt-4" data-aos='fade-up'>
                                                <p className="text-[12px] md:text-[14px] md:pl-20">
                                                    We then set out on a quest to find the finest products that embody your brand’s promise. Only the best will do, and we make sure every product aligns with the story we’re telling.
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>



                        {/*-------- process Seven ----------*/}
                        <div className="flex justify-end items-end  opacity-1" id="proseven" ref={seventhProcess} >
                            <div className="sticky top-28 z-10 h-[85vh] md:h-[65vh] w-full md:w-full  bg-white  drop-shadow-sm box flex justify-start items-start ">
                                <div className="relative w-full h-full  text-white overflow-hidden flex font-sans">

                                    <img
                                        src={imgSeven}
                                        alt="Background"
                                        className='absolute inset-0 w-full h-full object-cover transition-opacity duration-700 ease-in-out transform  '
                                    />

                                    {/* Overlay */}
                                    <div className="absolute inset-0 bg-black opacity-50 z-10 w-full"></div>


                                    <div className="content-section flex flex-col gap-y-6 justify-between md:flex-row items-start space-y-8 z-20 w-full md:w-[85%] md:mt-16 mx-auto">

                                        <div className='w-full  text-left transition-opacity  duration-700 ease-in-out px-4 py-6 md:px-0 md:py-0 ' data-aos='fade-up'>
                                            <div className="place-box text-[12px] font-light uppercase font-signika">
                                                Step 7
                                            </div>
                                            <div className="title-box-1 text-[24px] md:text-[32px] font-bold text-yellow-400 font-oswald" data-aos='fade-up'>
                                                {/* Designing the  Dream */}
                                            </div>
                                            <div className="mt-4">
                                                <img src={Design} className="w-60 object-cover " alt="none" />
                                            </div>
                                            <div className="desc mt-4" data-aos='fade-up'>
                                                <p className="text-[12px] md:text-[14px] md:pr-20">
                                                    From concept to creation, we bring your product to life. Designs that aren’t just functional—they’re pieces of art that showcase your brand in its best light.
                                                </p>
                                            </div>


                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*-------- process Eight ----------*/}
                        <div className="flex justify-end items-end opacity-1" id="proeight" ref={eighthProcess}>

                            <div className="sticky top-28 z-10 h-[80vh] md:h-[65vh]  w-full md:w-full   bg-white  shadow-lg drop-shadow-sm ">
                                <div className="relative w-full h-full text-white overflow-hidden flex font-sans">

                                    <img
                                        src={imgEight}
                                        alt="Background"
                                        className='absolute inset-0 w-full h-full object-cover transition-opacity duration-700 ease-in-out transform  '
                                    />

                                    {/* Overlay */}
                                    <div className="absolute inset-0 bg-black opacity-50 z-10 w-full"></div>


                                    <div className="content-section flex  gap-y-6 justify-between md:flex-row items-start space-y-8 z-20 w-full md:w-[85%] md:mt-16 mx-auto">


                                        <div className='w-full text-right flex flex-col justify-end items-end transition-opacity  duration-700 ease-in-out px-4 py-6 md:px-0 md:py-0 ' data-aos='fade-up'>
                                            <div className="place-box text-[12px] font-light uppercase font-signika">
                                                Step 8
                                            </div>
                                            <div className="title-box-1 text-[24px] md:text-[32px] font-bold text-yellow-400 font-oswald" data-aos='fade-up'>
                                                {/* Planning the Big Reveal */}
                                            </div>
                                            <div className="mt-6">
                                                <img src={Plan} className="w-64 object-cover " alt="none" />
                                            </div>
                                            <div className="desc mt-4" data-aos='fade-up'>
                                                <p className="text-[12px] md:text-[14px] md:pl-20">
                                                    Now it’s time to make your brand known. We design a comprehensive advertising plan that’s bold, strategic, and impactful. This is where the magic starts to unfold.
                                                </p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/*-------- process Nine ----------*/}
                        <div className="flex justify-end items-end  opacity-1" id="pronine" ref={ninethProcess} >
                            <div className="sticky top-28 z-10 h-[85vh] md:h-[65vh]  w-full md:w-full  bg-white  drop-shadow-sm box flex justify-start items-start ">
                                <div className="relative w-full  h-full text-white overflow-hidden flex font-sans">

                                    <img
                                        src={imgNine}
                                        alt="Background"
                                        className='absolute inset-0 w-full h-full object-cover transition-opacity duration-700 ease-in-out transform  '
                                    />

                                    {/* Overlay */}
                                    <div className="absolute inset-0 bg-black opacity-50 z-10 w-full"></div>


                                    <div className="content-section flex flex-col justify-between  md:flex-row items-start  space-y-8 z-20 w-full md:w-[85%] md:mt-16 mx-auto">

                                        <div className='w-full text-left transition-opacity  duration-700 ease-in-out px-4 py-6 md:px-0 md:py-0' data-aos='fade-up'>
                                            <div className="place-box text-[12px] font-light uppercase font-signika">
                                                Step 9
                                            </div>
                                            <div className="title-box-1 text-[24px] md:text-[32px] font-bold text-yellow-400 font-oswald" data-aos='fade-up'>
                                                {/* Bringing It to Life */}
                                            </div>
                                            <div className="mt-4">
                                                <img src={Bring} className="w-64 object-cover " alt="none" />
                                            </div>

                                            <div className="desc mt-4" data-aos='fade-up'>
                                                <p className="text-[12px] md:text-[14px] md:pr-20">
                                                    Lights, camera, action! We capture your brand’s essence through powerful ads that speak volumes. Every shot is designed to leave a lasting impression.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*-------- process Ten ----------*/}
                        <div className="flex justify-end items-end opacity-1" id="proten" ref={tenthProcess}>

                            <div className="sticky top-28 z-10 h-[80vh] md:h-[65vh] w-full  md:w-full   bg-white  shadow-lg drop-shadow-sm ">
                                <div className="relative w-full h-full text-white overflow-hidden flex font-sans">

                                    <img
                                        src={imgTen}
                                        alt="Background"
                                        className='absolute inset-0 w-full h-full object-cover transition-opacity duration-700 ease-in-out transform  '
                                    />

                                    {/* Overlay */}
                                    <div className="absolute inset-0 bg-black opacity-50 z-10 w-full"></div>


                                    <div className="content-section flex  justify-between md:flex-row items-start space-y-8 z-20 w-full md:w-[85%] md:mt-16 mx-auto">

                                        <div className='w-full text-right flex flex-col justify-end items-end  transition-opacity  duration-700 ease-in-out px-4 py-6 md:px-0 md:py-0' data-aos='fade-up'>
                                            <div className="place-box text-[12px]  font-light uppercase font-signika">
                                                Step 10
                                            </div>
                                            {/* <div className="title-box-1 text-[24px] md:text-[32px] font-bold text-yellow-400 font-oswald" data-aos='fade-up'>
                                                The World Watches
                                            </div> */}
                                            <div className="mt-4">
                                                <img src={World} className="w-64 object-cover " alt="none" />
                                            </div>
                                            <div className="desc mt-4" data-aos='fade-up'>
                                                <p className="text-[12px] md:text-[14px] md:pl-20">
                                                    With your ads in place, we release them to the world, ensuring your message reaches the right eyes at the right time. Your brand is no longer just a name—it’s a movement.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/*-------- process Elven ----------*/}
                        <div className="flex justify-end items-end  opacity-1" id="proelven" ref={eleventhProcess}>
                            <div className="sticky top-28 z-10 h-[85vh] md:h-[65vh] w-full md:w-full  bg-white  drop-shadow-sm box flex justify-start items-start ">
                                <div className="relative w-full  h-full text-white overflow-hidden flex font-sans">

                                    <img
                                        src={imgElven}
                                        alt="Background"
                                        className='absolute inset-0 w-full h-full object-cover transition-opacity duration-700 ease-in-out transform  '
                                    />

                                    {/* Overlay */}
                                    <div className="absolute inset-0 bg-black opacity-50 z-10 w-full"></div>


                                    <div className="content-section flex flex-col gap-y-6 justify-between md:flex-row items-start space-y-8 z-20 w-full md:w-[85%] md:mt-16 mx-auto">


                                        <div className='w-full text-left transition-opacity  duration-700 ease-in-out px-4 py-6 md:px-0 md:py-0 ' data-aos='fade-up'>
                                            <div className="place-box text-[12px] font-light uppercase font-signika">
                                                Step 11
                                            </div>
                                            <div className="mt-4">
                                                <img src={Digital} className="w-40 object-cover " alt="none" />
                                            </div>
                                            <div className="title-box-1 text-[24px] md:text-[32px] font-bold text-yellow-400 font-oswald" data-aos='fade-up'>
                                                Dominance
                                            </div>

                                            <div className="desc mt-4" data-aos='fade-up'>
                                                <p className="text-[12px] md:text-[14px] md:pr-20">
                                                    We take your brand into the digital world, where clicks turn into conversations. Through tailored digital marketing strategies, we ensure your brand stays top-of-mind across platforms.
                                                </p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/*-------- process Twele ----------*/}
                        <div className="flex justify-end items-end opacity-1" id="protwele" ref={twelethProcess}>

                            <div className="sticky top-28 z-10 h-[80vh] md:h-[65vh]  w-full md:w-full   bg-white  shadow-lg drop-shadow-sm ">
                                <div className="relative w-full h-full text-white overflow-hidden flex font-sans">

                                    <img
                                        src={imgTwele}
                                        alt="Background"
                                        className='absolute inset-0 w-full h-full object-cover transition-opacity duration-700 ease-in-out transform  '
                                    />

                                    {/* Overlay */}
                                    <div className="absolute inset-0 bg-black opacity-50 z-10 w-full"></div>


                                    <div className="content-section  gap-y-6 justify-between  md:flex-row items-start space-y-8 z-20 w-full md:w-[85%] md:mt-16 mx-auto">

                                        <div className='w-full text-right flex flex-col justify-end items-end transition-opacity  duration-700 ease-in-out px-4 py-6 md:px-0 md:py-0' data-aos='fade-up'>
                                            <div className="place-box text-[12px] font-light uppercase font-signika">
                                                Step 12
                                            </div>
                                            {/* <div className="title-box-1 text-[24px] md:text-[32px] font-bold text-yellow-400 font-oswald" data-aos='fade-up'>
                                                The Power of Influence
                                            </div> */}
                                            <div className="mt-4">
                                                <img src={Influnce} className="w-64 object-cover " alt="none" />
                                            </div>
                                            <div className="desc mt-4" data-aos='fade-up'>
                                                <p className="text-[12px] md:text-[14px] md:pl-20">
                                                    We then harness the voices of influencers who align with your brand, amplifying your message and creating authentic connections with your audience.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/*-------- process Elven ----------*/}
                        <div className="flex justify-end items-end ">
                            <div className="sticky top-28 z-10 h-[85vh] md:h-[65vh] w-full md:w-full  bg-white  drop-shadow-sm box flex justify-start items-start ">
                                <div className="relative w-full h-full text-white overflow-hidden flex font-sans">

                                    <img
                                        src={imgThritin}
                                        alt="Background"
                                        className='absolute inset-0 w-full h-full object-cover transition-opacity duration-700 ease-in-out transform  '
                                    />

                                    {/* Overlay */}
                                    <div className="absolute inset-0 bg-black opacity-50 z-10 w-full"></div>


                                    <div className="content-section flex flex-col md:flex-row gap-y-6 justify-between items-start space-y-8 z-20 w-full md:w-[85%] md:mt-16 mx-auto">

                                        <div className='w-full text-left transition-opacity  duration-700 ease-in-out px-4 py-6 md:px-0 md:py-0 ' data-aos='fade-up'>
                                            <div className="place-box text-[12px] font-light uppercase font-signika" >
                                                Step 13
                                            </div>
                                            {/* <div className="title-box-1 text-[24px] md:text-[32px] font-bold text-yellow-400 font-oswald" data-aos='fade-up'>
                                                Global Expansion
                                            </div> */}
                                            <div className="mt-4">
                                                <img src={Globel} className="w-64 object-cover " alt="none" />
                                            </div>
                                            <div className="desc mt-4" data-aos='fade-up'>
                                                <p className="text-[12px] md:text-[14px] md:pr-20">
                                                    Finally, your brand is no longer local—it’s global. We take your products to every corner of the world, ensuring your brand becomes a household name everywhere.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*-------- process fourtin ----------*/}
                        <div className="flex justify-end items-end">

                            <div className="sticky top-28 z-10 h-[80vh] md:h-[65vh]  w-full  md:w-full   bg-white  shadow-lg drop-shadow-sm ">
                                <div className="relative w-full h-full text-white overflow-hidden flex font-sans">

                                    <img
                                        src={imgfourtin}
                                        alt="Background"
                                        className='absolute inset-0 w-full h-full object-cover transition-opacity duration-700 ease-in-out transform  '
                                    />

                                    {/* Overlay */}
                                    <div className="absolute inset-0 bg-black opacity-50 z-10 w-full"></div>


                                    <div className="content-section   md:flex-row justify-between gap-y-6 items-start space-y-8 z-20 w-full md:w-[85%] md:mt-16 mx-auto">


                                        <div className='text-right flex flex-col justify-end items-end transition-opacity  duration-700 ease-in-out px-4 py-6 md:px-0 md:py-0 ' data-aos='fade-up'>
                                            <div className="place-box text-[12px] font-light uppercase font-signika">
                                                Step 14
                                            </div>
                                            <div className="title-box-1 text-[24px] md:text-[32px] font-bold text-yellow-400 font-oswald" data-aos='fade-up'>
                                                And So the Story Goes.
                                            </div>
                                            <div className="mt-4">
                                                <img src={join} className="w-40 object-cover " alt="none" />
                                            </div>
                                            <div className="desc mt-4" data-aos='fade-up'>
                                                <p className="text-[12px] md:text-[14px] md:pl-20">
                                                    From the first spark of an idea to a globally recognized brand, together we’ve turned your dream into a reality. And this? This is only the beginning. The story of your brand is just getting started, and it’s destined for greatness.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                    </div>
                </div>
            </section>




        </>
    )
}

export default Allprocess