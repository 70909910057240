// Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/logo/vishnu_ads_logo.png'

import footer from '../images/footer.jpg'

import border from '../images/footer_border.png'

//icons
import { FaInstagram } from "react-icons/fa";
import { FiFacebook } from "react-icons/fi";
import { RiTwitterXFill } from "react-icons/ri";
import { FaPinterestP } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa6";
import { TiSocialLinkedin } from "react-icons/ti";



function Footer() {
    return (
        <footer className="bg-  font-[poppins]">
            <div className='relative overflow-hidden'>

                {/* <div className='absolute -bottom-16 w-full z-20'>
                    <img src={border} className='w-full object-cover' alt='none' />
                </div> */}

                <div className="relative">
                    {/* Image Section */}
                    <div className='relative'>
                        <img
                            src={footer}
                            className="w-full object-cover  absolute inset-0 z-0 h-[50vh] md:h-[45vh] clip-image"
                            alt="footer background" />

                        <div className='absolute inset-0 bg-white/90 backdrop-blur-sm z-10' ></div>



                        {/* Footer Content */}
                        <div className="z-20 relative bg- md:h-[45vh] pt-6 md:pt-10">

                            <div className="grid grid-cols-1 md:grid-cols-3 gap-x-10 px-4 gap-y-8">
                                {/* Second grid - First on mobile, second on larger screens */}
                                <div className="hidden md:flex flex-col gap-y-2 md:gap-y-6 justify-start md:justify-center w-full items-center order-1 md:order-2">
                                    <div className="flex md:items-center justify-start md:justify-center mb-3 w-full">
                                        {/* <img src={logo} className="w-16 h-16 animate-spin duration-1000  delay-1000 object-cover rounded-full" /> */}
                                    </div>
                                    <p className="text-gray-950 font-roboto text-start md:text-center text-[14px] w-full">
                                        {/* We Shape Tomorrow's Brand Today */}
                                    </p>
                                </div>

                                {/* First grid - Second on mobile, first on larger screens */}
                                <div className="flex flex-col gap-y-6 md:justify-center md:items-center order-2 md:order-1">
                                    <div className="mt-4">
                                        <div className="mb-4 md:mb-6">
                                            <p className="text-start text-[#01b0b2] text-[14px] md:text-[18px]">Follow Our Pages</p>
                                        </div>

                                        <div className="flex items-center gap-x-5 md:gap-x-8">
                                            <div>
                                                <Link to='https://www.instagram.com/vishnu_ads?igsh=dTEydjRmOXkwZXF2' target='_blank'>
                                                    <div className="bg-white shadow-sm drop-shadow-md p-1.5 rounded-md inner-shadow hover-inner-shadow">
                                                        <FaInstagram className="text-[12px] md:text-[16px]" />
                                                    </div>
                                                </Link>
                                            </div>
                                            <div>
                                                <Link to='https://www.facebook.com/share/9h97QEk3NMbq9jap/' target='_blank'>
                                                    <div className="bg-white shadow-sm drop-shadow-md p-1.5 rounded-md inner-shadow hover-inner-shadow">
                                                        <FiFacebook className="text-[12px] md:text-[16px]" />
                                                    </div>
                                                </Link>
                                            </div>
                                            <div>
                                                <Link to='https://x.com/VishnuadsDM?t=9Hy0g4hBzh4stcSVegw2zw&s=09' target='_blank'>
                                                    <div className="bg-white shadow-sm drop-shadow-md p-1.5 rounded-md inner-shadow hover-inner-shadow">
                                                        <RiTwitterXFill className="text-[12px] md:text-[16px]" />
                                                    </div>
                                                </Link>
                                            </div>
                                            <div>
                                                <Link to='https://pin.it/3RH0UtRyB' target='_blank'>
                                                    <div className="bg-white shadow-sm drop-shadow-md p-1.5 rounded-md inner-shadow hover-inner-shadow">
                                                        <FaPinterestP className="text-[12px] md:text-[16px]" />
                                                    </div>
                                                </Link>
                                            </div>
                                            
                                            <div>
                                                <Link to="https://www.linkedin.com/in/vishnu-ads-930b15267?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" target='_blank' className="">
                                                    <div className="bg-white shadow-sm drop-shadow-md p-1.5 rounded-md inner-shadow hover-inner-shadow">
                                                        <TiSocialLinkedin className="text-[12px] md:text-[16px]" />
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                {/* Third grid - Stays in third position */}
                                <div className="flex flex-col gap-y-6  md:justify-center md:items-center order-3">
                                    <div className="mt-4">
                                        <div className=" mb-4 md:mb-6">
                                            <p className="text-start text-[#01b0b2] text-[14px] md:text-[18px]">Reach Out</p>
                                        </div>
                                        <div>
                                            <div className="flex flex-col gap-y-2 text-[12px] md:text-[14px] text-gray-950">
                                                <p>+91  85318 18181</p>
                                                <p>contact@vishnuads.com</p>
                                                <div>
                                                    <p> 9, David Towers, 5th floor, Ashoknagar main Road, </p>
                                                    <p> Kodambakkam, Chennai, Tamil Nadu 600024</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>




                            <div className='border-t border-gray-400 mt-6  mx-auto'></div>

                            {/* Bottom Bar */}
                            <div className="border-t border-gray-300 pt-5 pb-8">
                                <div className="flex flex-col-reverse md:flex-row justify-between items-center gap-y-3 text-gray-200 text-sm w-full px-4 md:w-[90%] mx-auto">
                                    <p className="text-[12px] md:text-[14px] text-gray-900">©2006™ - Vishnu Ads And Ventures Pvt Ltd</p>
                                    <div className="mt-2 md:mt-0 flex items-center space-x-2">
                                        <span className='text-gray-400'>Made with </span>
                                        <button className="bg-gray-900 px-3 py-1 rounded-full text-xs font-medium text-gray-300 cursor-progress">Vishnu Ads</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </footer>
    );
}

export default Footer;



<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-y-5 md:gap-8 md:text-left w-full md:w-[90%] px-4 md:mx-auto">

    {/* Column 4 - Logo and Description */}
    <div className="md:pl-16 py-4 md:py-10 md:text-left mt-10 md:mt-0">
        <div className="flex items-center justify-start mb-3">
            <img src={logo} className="w-20 object-cover" />
        </div>
        <p className="text-gray-500 font-roboto text-[14px]">The Real-time & powerful 3D design for web.</p>
        <div className="mt-4">
            {/* Social media links can go here */}
        </div>
    </div>

    {/* Column 1 - Product */}
    <div className="md:pl-16 py-4 md:py-10">
        <h4 className="text-[14px] md:text-[16px] font-semibold mb-3 text-black">Product</h4>
        <ul className="space-y-2 text-gray-400 font-roboto text-[12px] md:text-[14px]">
            <li><a href="#" className="hover:text-blue-100 text-blue-500">Features</a></li>
            <li><a href="#" className="hover:text-gray-100">Pricing</a></li>
            <li><a href="#" className="hover:text-gray-100">Download</a></li>
        </ul>
    </div>

    {/* Column 2 - Company */}
    <div className="md:pl-16 py-4 md:py-10">
        <h4 className="text-[14px] md:text-[16px] font-semibold mb-3 text-black">Company</h4>
        <ul className="space-y-2 text-gray-400 font-roboto text-[12px] md:text-[14px]">
            <li><a href="#" className="hover:text-gray-100">About</a></li>
            <li><a href="#" className="hover:text-gray-100">Blog</a></li>
            <li><a href="#" className="hover:text-gray-100">Contact</a></li>
        </ul>
    </div>

    {/* Column 3 - Community */}
    <div className="md:pl-16 py-4 md:py-10">
        <h4 className="text-[14px] md:text-[16px] font-semibold mb-3 text-black">Contact</h4>
        <ul className="space-y-2 text-gray-400 font-roboto text-[12px] md:text-[14px]">
            <li><a href="#" className="hover:text-gray-100">Help</a></li>
            <li><a href="#" className="hover:text-gray-100">Discord</a></li>
            <li><a href="#" className="hover:text-gray-100">Twitter</a></li>
            <li><a href="#" className="hover:text-gray-100">LinkedIn</a></li>
        </ul>
    </div>
</div>