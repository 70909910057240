import React, { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; // Import Swiper styles
import { gsap } from 'gsap'; // GSAP for animations


import titleOne from '../images/new_proces/journey.png'
import titleTwo from '../images/new_proces/begins.png'
import titleThree from '../images/new_proces/strategy.png'
import titleFour from '../images/new_proces/building.png'
import titleFive from '../images/new_proces/art_of_branding.png'
import titleSix from '../images/new_proces/sourcing.png'
import titleSeven from '../images/new_proces/design.png'
import titleEight from '../images/new_proces/planning.png'
import titleNine from '../images/new_proces/bringing.png'
import titleTen from '../images/new_proces/world_watches.png'
import title11 from '../images/new_proces/digital.png'
import title12 from '../images/new_proces/power_of_influence.png'
import title13 from '../images/new_proces/global.png'
import title14 from '../images/new_proces/and.png'



import icon from '../images/new_proces/icon.png'

// icons
import { TiArrowLeftThick, TiArrowRightThick } from "react-icons/ti";


const steps = [
    {
        title: titleOne,
        description: 'A brand waiting to be born—an idea brimming with potential but needing a guiding hand to bring it to life. That’s where we come in.',
        icon: icon
    },

    {
        title: titleTwo,
        description: 'We start by listening. Deeply. Understanding your vision, your dreams, and your goals. Your story is our first chapter, and we can’t wait to write it with you',
        icon: 'https://controleur.ca/wp-content/uploads/2021/11/performance.svg'
    },

    {
        title: titleThree,
        description: 'With a clear understanding of your brand, we craft a strategy that feels like magic. A roadmap that positions your brand to stand out, resonate, and engage. We set the stage for greatness.',
        icon: 'https://controleur.ca/wp-content/uploads/2021/11/compass.svg'
    },

    {
        title: titleFour,
        description: 'Now that we have your brand’s heartbeat, we make it official. The legalities are handled, and your company stands tall, ready to conquer the world.',
        icon: 'https://controleur.ca/wp-content/uploads/2021/10/expand.svg'
    },

    {
        title: titleFive,
        description: 'We dive into the creative depths to design your brand’s identity—a look, a feel, and a voice that captures who you are and speaks to those you seek to connect with. Every detail is meticulously crafted to tell your story at first sight.',
        icon: 'https://controleur.ca/wp-content/uploads/2021/10/rocket.svg'
    },

    {
        title: titleSix,
        description: 'We then set out on a quest to find the finest products that embody your brand’s promise. Only the best will do, and we make sure every product aligns with the story we’re telling.',
        icon: 'https://controleur.ca/wp-content/uploads/2021/10/expand.svg'
    },

    {
        title: titleSeven,
        description: 'From concept to creation, we bring your product to life. Designs that aren’t just functional—they’re pieces of art that showcase your brand in its best light.',
        icon: 'https://controleur.ca/wp-content/uploads/2021/10/expand.svg'
    },

    {
        title: titleEight,
        description: 'Now it’s time to make your brand known. We design a comprehensive advertising plan that’s bold, strategic, and impactful. This is where the magic starts to unfold.',
        icon: 'https://controleur.ca/wp-content/uploads/2021/10/expand.svg'
    },
    {
        title: titleNine,
        description: 'Lights, camera, action! We capture your brand’s essence through powerful ads that speak volumes. Every shot is designed to leave a lasting impression.',
        icon: 'https://controleur.ca/wp-content/uploads/2021/10/expand.svg'
    },
    {
        title: titleTen,
        description: 'With your ads in place, we release them to the world, ensuring your message reaches the right eyes at the right time. Your brand is no longer just a name—it’s a movement.',
        icon: 'https://controleur.ca/wp-content/uploads/2021/10/expand.svg'
    },
    {
        title: title11,
        description: 'We take your brand into the digital world, where clicks turn into conversations. Through tailored digital marketing strategies, we ensure your brand stays top-of-mind across platforms.',
        icon: 'https://controleur.ca/wp-content/uploads/2021/10/expand.svg'
    },
    {
        title: title12,
        description: 'We then harness the voices of influencers who align with your brand, amplifying your message and creating authentic connections with your audience.',
        icon: 'https://controleur.ca/wp-content/uploads/2021/10/expand.svg'
    },
    {
        title: title13,
        description: 'Finally, your brand is no longer local—it’s global. We take your products to every corner of the world, ensuring your brand becomes a household name everywhere.',
        icon: 'https://controleur.ca/wp-content/uploads/2021/10/expand.svg'
    },
    {
        title: title14,
        description: 'From the first spark of an idea to a globally recognized brand, together we’ve turned your dream into a reality. And this? This is only the beginning. The story of your brand is just getting started, and it’s destined for greatness.',
        icon: 'https://controleur.ca/wp-content/uploads/2021/10/expand.svg'
    },

];

const AllProcessNew = () => {
    const [currentStep, setCurrentStep] = useState(2); // Start from slide 3 (index 2)
    const swiperRef = useRef(null); // Reference to Swiper instance
    const descriptionRef = useRef([]);

    useEffect(() => {
        // Animate the wave path on component mount
        gsap.fromTo(
            '.wave-path',
            { strokeDashoffset: 1000 }, // Start from a "drawn" path offset
            { strokeDashoffset: 0, duration: 2, ease: 'power2.out' }
        );
    }, []);

    useEffect(() => {
        // Fade-up animation for the description when the step changes
        gsap.fromTo(
            descriptionRef.current[currentStep],
            { opacity: 0, y: 50 }, // Starting from invisible and 20px down
            { opacity: 1, y: 0, duration: 0.8, ease: 'power2.out' } // Fade in and move up
        );
    }, [currentStep]); // Trigger animation whenever the current step changes


    useEffect(() => {
        // Trigger the wave animation every time the slide changes
        gsap.fromTo(
            '.wave-path',
            { strokeDashoffset: 1000 }, // Start from a "drawn" path offset
            { strokeDashoffset: 0, duration: 2, ease: 'power2.out' }
        );

        // Animate the step numbers with a "bounce" effect on change
        gsap.to('.step-number', {
            scale: 1.5, // Increase size temporarily
            duration: 0.4,
            ease: 'bounce.out',
            stagger: 0.1,
            repeat: 0,  // Only animate once when the step changes
        });


        gsap.fromTo(
            ".big-number",          // Target elements
            {                       // Starting properties
                scale: 2,
                y: 20,
            },
            {                       // Ending properties
                scale: 1.5,             // Resetting scale (optional)
                opacity: 1,
                y: 0,
                duration: 1,
                ease: 'power2.out',
                stagger: 0.1,
                repeat: 0,
            }
        );

    }, [currentStep]);

    // console.log(currentStep)


    // Function to move left
    const moveLeft = () => {
        swiperRef.current.swiper.slidePrev();
    };

    // Function to move right
    const moveRight = () => {
        swiperRef.current.swiper.slideNext();
    };

    return (
        <section className="pt-10 pb-10 px-4 bg-gray-950">
            <Swiper
                ref={swiperRef} // Link the Swiper to the reference
                spaceBetween={30}
                // slidesPerView={3} // Default for larger screens
                centeredSlides={true} // Ensure slides are centered
                initialSlide={1} // Show slide 3 initially
                speed={800}
                onSlideChange={(swiper) => setCurrentStep(swiper.activeIndex)}
                className="mb-10"
                breakpoints={{
                    // When the screen width is less than 640px (mobile)
                    640: {
                        slidesPerView: 1, // Show 1 slide
                        spaceBetween: 10, // Adjust the space between slides
                        // initialSlide: 1
                    },
                    // When the screen width is greater than or equal to 640px (tablet and up)
                    768: {
                        slidesPerView: 2, // Show 2 slides
                        spaceBetween: 20,
                        // initialSlide: 1
                    },
                    // When the screen width is greater than or equal to 1024px (desktop and up)
                    1024: {
                        slidesPerView: 3, // Show 3 slides
                        spaceBetween: 30,
                        // initialSlide: 1
                    },
                }}
            >
                {steps.map((step, index) => (
                    <SwiperSlide key={index}>
                        <div className="flex flex-col items-center justify-center text-center   font-[poppins] px-4 md:px-16  mb-12 cursor-pointer">
                            {/* <div className='bg-white p-3 rounded-2xl shadow-lg drop-shadow-lg mb-6'>
                                <img src={step.icon} alt={step.title} className="w-16 h-16" />
                            </div> */}
                            {/* <h4 className="text-[16px] font-semibold mb-2">{step.title}</h4> */}
                            <div className='mb-6 flex justify-center items-center'>
                                <img src={step.title} alt={step.title} className=" w-32 md:w-32  object-cover" />
                            </div>
                            <p
                                ref={(el) => descriptionRef.current[index] = el} // Assign ref for each description
                                className={`text-white text-[12px] md:text-[14px] max-w-md font-[roboto] ${currentStep === index ? 'block' : 'hidden'}`}
                            >
                                {step.description}
                            </p>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>

            {/* Bottom Navigation */}
            <div className="relative flex justify-center items-center  mt-12 md:mt-20">
                <div className="relative flex justify-center items-center w-full">
                    {/* SVG Wave Line with Dots */}
                    <svg className="absolute w-full h-12 " viewBox="0 0 300 50" preserveAspectRatio="none">
                        <path
                            className="wave-path"
                            d="M0,30 C50,70 100,0 150,30 C200,60 250,0 300,30"
                            stroke="#aaefff"
                            fill="transparent"
                            strokeWidth="1.8"
                            strokeDasharray="0.9,1"
                        />
                    </svg>

                    {/* Step Numbers */}
                    <div className="absolute flex justify-between items-center w-full md:w-[80%] top-[50%] mt-2 -translate-y-1/2 z-10 px-4 md:px-16">
                        {steps.map((_, index) => (
                            // Show 3 numbers visible: the current step and adjacent steps
                            (currentStep === index || currentStep === index - 1 || currentStep === index + 1) && (
                                <div
                                    key={index}
                                    className={`step-number rounded-full flex items-center justify-center font-bold border-2 ${currentStep === index ? 'big-number border-4 md:border-8 border-[#aaefff] text-black bg-[#ffffff] w-12  md:w-20  h-12 md:h-20 scale-105  text-[20px] md:text-[26px] ' : 'bg-gray-50 border-[#aaefff] text-gray-900 text-[10px] md:text-[12px] w-6 md:w-8 h-6 md:h-8'}`}
                                >

                                    {index + 1}
                                </div>
                            )
                        ))}
                    </div>
                </div>
            </div>


            {/* Left and Right Arrows */}
            <div className='flex items-center gap-x-4 md:gap-x-8 mt-20 md:mt-28 justify-start  w-full px-4 md:w-[80%] mx-auto '>
                <div className="z-10">
                    <button onClick={moveLeft} className="bg-white hover:bg-transparent hover:border hover:border-gray-100 text-black hover:text-gray-50 p-2 hover:scale-105 duration-100 rounded-full shadow-lg">
                        <TiArrowLeftThick className='text-[14px] md:text-[18px]' />
                    </button>
                </div>

                <div className="z-10">
                    <button onClick={moveRight} className="bg-white hover:bg-transparent hover:border hover:border-gray-100 text-black hover:text-gray-50  p-2 hover:scale-105 duration-100 rounded-full shadow-lg">
                        <TiArrowRightThick className=' text-[14px] md:text-[18px]' />
                    </button>
                </div>
            </div>

        </section>
    );
};

export default AllProcessNew;
