import React from 'react'
import { useState, useEffect } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';


import { FaMobileScreen } from "react-icons/fa6";
import { HiOutlineMenuAlt1 } from "react-icons/hi";



import { FiFacebook } from "react-icons/fi";
import { FaWhatsapp } from "react-icons/fa";
import { CiLinkedin } from "react-icons/ci";
import { ImPinterest } from "react-icons/im";
import { FaInstagram } from "react-icons/fa";

import { GoDotFill } from "react-icons/go";
import { CiStreamOn } from "react-icons/ci";
import { CiMemoPad } from "react-icons/ci";
import { TbWorld } from "react-icons/tb";
import { MdMiscellaneousServices } from "react-icons/md";
import { PiSpeakerHighBold } from "react-icons/pi";
import { MdCalendarMonth } from "react-icons/md";
import { FaRoute } from "react-icons/fa6";
import { RiContactsBook3Line } from "react-icons/ri";
import { TiHomeOutline } from "react-icons/ti";

import { IoMenuSharp } from "react-icons/io5";




// import logo from '../images/64.png'
import logo from '../images/logo/vishnu_ads_logo.png'
import black from '../images/logo/vishnu_ads_logo.png'


const Header = () => {

    // State to manage the navbar's visibility
    const [nav, setNav] = useState(false);


    // Toggle function to handle the navbar's display
    const handleNav = () => {
        setNav(nav);
    };

    //color change
    const [navbarBg, setNavbarBg] = useState('  bg-white/30 ');

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setNavbarBg('bg-white/10 shadow-md ');
            } else {
                setNavbarBg('bg-white/30');
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Remove event listener on cleanup
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const [activeLink, setActiveLink] = useState("home");

    const handleSetActive = (link) => {
        setActiveLink(link);
        topPage();
    };

    function topPage() {
        window.scroll(0, 0)
    }

    //mobile view click the link hide the navbar

    const [hide, setHide] = useState(true)

    // function clicklink() {
    //     setHide(false)
    // }


    return (
        <>

            {/* ------ top navbar -------- */}
            {/* <div className='flex items-center bg-sky-900  h-8 md:h-11 w-full'>

                <div className="overflow-hidden w-[50%] h-full  md:pl-14">
                    <div className="animate-marquee font-[roboto] whitespace-nowrap">
                        <p className='text-gray-50 tracking-[.04em] text-[8px] sm:text-[12px] md:text-[14px] inline-block'>
                            What it does: Prevents search engines from following the links on the page. <span className='text-teal-300 cursor-pointer'>Contact Now</span>
                        </p>
                    </div>

                    <div className='bg-white w-fit px-4 md:px-6 h-full flex justify-center items-center'>
                        <a href="tel:+919884683888" target="_blank" >
                            <div className='flex items-center gap-x-1 md:gap-x-3'>
                                <span><img src={phone} className='w-7 md:w-9 object-cover' /></span>
                                <span className='font-[roboto] text-[11px] md:text-[12px] text-black font-medium'> +91 9884683888 </span>
                            </div>
                        </a>
                    </div>

                </div>

                <div className='w-[50%] flex justify-end pr-4 sm:pr-6 md:pr-8 lg:pr-10'>
                    <div>
                        <ul className='flex items-center gap-x-2.5 md:gap-x-4'>
                            <a href="https://api.whatsapp.com/send?phone=9884683888" target='_blank'> <li className='bg-white  border border-gray-400 shadow-gray-100 rounded-full p-1 hover:animate-bounce duration-150 shadow-sm drop-shadow-md'><FaWhatsapp className='text-gray-950 hover:text-teal-900 text-[8px] sm:text-[12]  md:text-[16px] ' /> </li> </a>
                            <a href="https://www.facebook.com/Vishnuadagency" target="_blank"> <li className='bg-white  border border-gray-400 shadow-gray-100  rounded-full p-1 hover:animate-bounce duration-150 shadow-sm drop-shadow-md'><FiFacebook className='text-gray-950 hover:text-teal-900 text-[8px] sm:text-[12]  md:text-[16px] ' /> </li> </a>
                            <a href="https://www.instagram.com/vishnu_ads" target="_blank"> <li className='bg-white  border border-gray-400 shadow-gray-100  rounded-full p-1 hover:animate-bounce duration-150 shadow-sm drop-shadow-md'><FaInstagram className='text-gray-950 hover:text-teal-900  text-[8px] sm:text-[12]  md:text-[16px] ' /> </li> </a>
                            <a href="https://www.linkedin.com/feed/" target='_blank'> <li className='bg-white  border border-gray-400 shadow-gray-100  rounded-full p-1 hover:animate-bounce duration-150 shadow-sm drop-shadow-md'><CiLinkedin className='text-gray-950 hover:text-teal-900 text-[8px] sm:text-[12]  md:text-[16px] ' /> </li> </a>
                            <a href="https://in.pinterest.com/vishnuads1/" target='_blank'> <li className='bg-white  border border-gray-400 shadow-gray-100  rounded-full p-1 hover:animate-bounce duration-150 shadow-sm drop-shadow-md'><ImPinterest className='text-gray-950 hover:text-teal-900  text-[8px] sm:text-[12]  md:text-[16px] ' /> </li> </a>
                        </ul>
                    </div>

                </div>
            </div> */}


            {/* -------- second navbar ------- */}

            <nav className={` w-full sticky top-0 z-50  py-2  md:py-3.5   ${navbarBg} `}>
                <div className="max-w-full mx-auto font-sans  ">
                    {/* Logo */}
                    <div className='flex justify-between items-center px-4 sm:px-9 w-full xl:w-[95%] mx-auto'>


                        <div className='w-full md:w-fit '>
                            <Link to='/' onClick={topPage}>
                                <img src={logo} className=' w-20 sm:w-20 md:w-24 object-cover' alt="" />
                            </Link>
                        </div>


                        {/* Desktop Navigation */}
                        {/* <ul className='hidden lg:flex w-fit font-semibold gap-x-4 xl:gap-x-16 '>

                            <li className="cursor-pointer duration-300 text-[12px] xl:text-[14px]">
                                <Link to="/" smooth={true} duration={500} onClick={() => handleSetActive("home")}>
                                    <p className="relative group flex items-center">
                                        <button
                                            className={`font-semibold ${activeLink === "home" ? "text-[#ff8c00]" : "text-gray-500"
                                                } hover:text-black focus:text-sky-400`}
                                        >
                                            Home
                                        </button>
                                    </p>
                                </Link>
                            </li>


                            <li className="cursor-pointer duration-300 text-[12px] xl:text-[14px]">
                                <Link to="/about" smooth={true} duration={500} onClick={() => handleSetActive("about")}>
                                    <p className="relative group flex items-center">
                                        <button
                                            className={`font-semibold ${activeLink === "about" ? "text-[#ff8c00]" : "text-gray-900"
                                                } hover:text-gray-700 focus:text-sky-400`}
                                        >
                                            About
                                        </button>
                                    </p>
                                </Link>
                            </li>

                            <li className="cursor-pointer duration-300 text-[12px] xl:text-[14px]">
                                <Link to="/services" smooth={true} duration={500} onClick={() => handleSetActive("services")}>
                                    <p className="relative group flex items-center">
                                        <button
                                            className={`font-semibold ${activeLink === "services" ? "text-[#ff8c00]" : "text-gray-900"
                                                } hover:text-gray-700 focus:text-sky-400`}
                                        >
                                            Services
                                        </button>
                                    </p>
                                </Link>
                            </li>

                            <li className="cursor-pointer duration-300 text-[12px] xl:text-[14px]">
                                <Link to="/contact" smooth={true} duration={500} onClick={() => handleSetActive("contact")}>
                                    <p className="relative group flex items-center">
                                        <button
                                            className={`font-semibold ${activeLink === "contact" ? "text-[#ff8c00]" : "text-gray-900"
                                                } hover:text-gray-700 focus:text-sky-400`}
                                        >
                                            Contact
                                        </button>
                                    </p>
                                </Link>
                            </li>
                        </ul> */}

                        {/* <div className="cursor-pointer ml-8  md:mt-0 md:ml-0 w-full md:w-fit">
                            <div className="mx-auto flex w-fit md:w-full max-w-lg items-center justify-center">
                                <a href="https://api.whatsapp.com/send?phone=9884683888" target="_blank" rel="noopener noreferrer">
                                    <button className="flex items-center gap-x-2 group  font-semibold shadow-sm drop-shadow-sm font-[poppins] bg-gray-200 hover:bg-gray-950 hover:text-gray-50  text-black   px-3 md:px-4 py-2 md:py-2.5 uppercase rounded-xl  border  border-gray-100 hover:border-gray-200  text-[11px] md:text-[14px] hover:scale-105 duration-200">
                                        Get a Quote
                                    </button>
                                </a>
                            </div>
                        </div> */}


                        {/* Mobile Navigation Icon */}
                        <div onClick={handleNav} className='block lg:flex'>
                            {nav ? <AiOutlineClose className='w-16 sm:w-16 md:w-20 text-teal-500 cursor-pointer' /> : <IoMenuSharp className='text-[22px] w-16 sm:w-16 md:w-24 cursor-pointer text-teal-500 font-bold' />}
                        </div>

                        {/* Mobile Navigation Menu */}
                        {
                            hide &&
                            <ul
                                className={
                                    nav
                                        ? 'fixed lg:flex flex-col left-0 top-0 w-[80%] md:w-[40%] h-screen  bg-blue-400/40 ease-in-out duration-500 md:pl-10 z-50'
                                        : 'ease-in-out w-[70%] md:w-[40%] duration-500 fixed top-0 bottom-0 left-[-100%] z-50'
                                }
                            >

                                {/* Mobile Logo */}

                                {/* <Link to='/' onClick={topPage} className='w-full' >
                                    <img src={black} className=' w-12 sm:w-24  pt-10  ml-8' alt="" onClick={handleNav} />
                                </Link> */}

                                {/* Mobile Navigation Items */}

                                <li className=' ml-8 cursor-pointer duration-300 text-white  text-[16px] mt-32' >
                                    <Link to="/" onClick={topPage}>
                                        <p className="relative group flex items-center">
                                            <button class="font-semibold tracking-[.02em]  hover:text-white/50  focus:text-red-600 uppercase flex items-center gap-x-2" onClick={handleNav} > Home</button>
                                        </p>
                                    </Link>
                                </li>

                                <li className=' ml-8 cursor-pointer duration-300 text-white  text-[16px] mt-8' >
                                    <Link to="/about" onClick={topPage}>
                                        <p className="relative group flex items-center">
                                            <button class="font-semibold tracking-[.02em]  hover:text-white/50  focus:text-red-600 uppercase flex items-center gap-x-2" onClick={handleNav} >  About Us</button>
                                        </p>
                                    </Link>
                                </li>

                                <li className=' ml-8 cursor-pointer duration-300 text-white  text-[16px] mt-8' onClick={topPage}>
                                    <Link to="/services" className='relative group' onClick={handleNav}>

                                        <p className="relative group flex items-center">
                                            <button class="  font-semibold  tracking-[.02em]  hover:text-white/50  focus:text-red-600 uppercase flex items-center gap-x-2"> Services</button>
                                        </p>

                                    </Link>
                                </li>


                                <li className=' ml-8 cursor-pointer duration-300 text-white  text-[16px] mt-8' >
                                    <Link to="/contact" smooth={true} duration={500} onClick={topPage}>
                                        <p className="relative group flex items-center">
                                            <button class="font-semibold  tracking-[.02em]  hover:text-white/50  focus:text-red-600 uppercase flex items-center gap-x-2" onClick={handleNav}>  Contact</button>
                                        </p>
                                    </Link>
                                </li>

                                <li className='mt-14 pl-6 md:pl-0 flex items-center gap-x-6'>
                                    <div onClick={handleNav} className='block lg:flex'>
                                        <button className='px-8 py-1.5 text-white font-semibold  text-[12px] bg-black  rounded-md'>Close</button>
                                    </div>

                                    <div>
                                        <Link to='/contact' onClick={handleNav}>
                                            <button className='px-5 py-1.5 text-black border border-white hover:bg-white font-semibold  text-[12px]  rounded-md'>Contact</button>
                                        </Link>
                                    </div>
                                </li>

                            </ul>
                        }

                    </div>
                </div>
            </nav >
        </>
    )
}

export default Header